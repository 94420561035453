import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navbar from './Component/Navbar/Navbar';
import TopNavbar from './Component/Navbar/TopNavbar';
import BottomNav from './Component/Navbar/BottomNav';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from 'react-router-dom';


const Main = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Set playback rate
      videoElement.playbackRate = 0.5;

      // Add event listener to resume if paused
      const handlePause = () => {
        if (videoElement.paused) {
          videoElement.play();
        }
      };

      // Listen to pause event and resume
      videoElement.addEventListener("pause", handlePause);

      // Cleanup event listener
      return () => {
        videoElement.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  return (
    <main className='main'>
      <video 
        ref={videoRef} 
        className='backvideo' 
        src="/backgroundvideo.mp4" 
        alt="backgroundvideo" 
        autoPlay 
        muted 
        loop={true} 
        playsInline 
      />
      <div className='Navbarmaincontainer'>
        <Navbar/>
      </div>
      <div className="main-container">
        <App/>
      </div>
      <div className='footer-container'>
        <TopNavbar/>
      </div>
      <BottomNav/>
    </main>
  );
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: 2000,
      cacheTime: 180000, // Cache time is now 3 minutes (180,000 ms)
      retry: 2, // Retry the request up to 2 times
      refetchOnWindowFocus: false, // No refetch on window focus
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
