import * as React from "react";
import { motion } from "framer-motion";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    strokeLinecap="round"
    stroke="#fff"
    {...props}
  />
);

export const MenuToggle = ({ toggle, isOpen }) => (
  <button className="MenuTogglebtn" onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      {/* Top Line */}
      <Path
        animate={isOpen ? "open" : "closed"}
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
        transition={{ duration: 0.3 }}
      />

      {/* Middle Line - fades out when open */}
      <Path
        d="M 2 9.423 L 20 9.423"
        animate={isOpen ? "open" : "closed"}
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        initial={{ opacity: 1 }} // Ensure it starts visible when closed
        transition={{ duration: 0.3 }}
      />

      {/* Bottom Line */}
      <Path
        animate={isOpen ? "open" : "closed"}
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
        transition={{ duration: 0.3 }}
      />
    </svg>
  </button>
);
