import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import VideoContainer from "../../Component/Video-Container/VideoContainer";

export const GetStartedbtn = ({ btnvalue, iconbtn, Linklaunch }) => {
  const [isHover, setisHover] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    setShowVideo(true); // Show the video when the button is clicked
  };

  const handleVideoEnd = () => {
    setShowVideo(false); // Hide the video after it ends
    // window.open(Linklaunch, "_blank"); 
    // setTimeout(() => {
    //   navigate(Linklaunch);
    // }, 6000);

    navigate(Linklaunch);
  };

  return (
    <>
      <button
        className="launchbtn mx-auto"
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        onClick={handleGetStartedClick} // Trigger video on click
        style={{
          width:
            btnvalue === "Testimonials"
              ? "240px"
              : btnvalue === "Privacy Policy"
              ? "250px"
              : btnvalue === "Newsletter Signup"
              ? "298px"
              : "",
        }}
      >
        <div className="launchbtnwrapper1">
          <div className="gvzymi">
            <svg
              id="hhhhhheight"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="27.5"
                cy="27.5"
                r="26.5"
                stroke="url(#paint0_angular)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="3 20 8 1 10 8"
              ></circle>
              <defs>
                <radialGradient
                  id="paint0_angular"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(27.5 27.5) rotate(107.904) scale(34.1541)"
                >
                  <stop offset="0.0870822" stopColor="#000000"></stop>
                  <stop offset="0.69191" stopColor="#000000"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="kRiXyW">
          <Lottie
            animationData={iconbtn}
            loop={!isHover}
            isStopped={!isHover}
            autoplay={isHover}
          />
        </div>
        <div className="cbypVx">{btnvalue}</div>
      </button>

      {/* Show VideoContainer when showVideo is true */}
      {showVideo && <VideoContainer onVideoEnd={handleVideoEnd} />}
    </>
  );
};
