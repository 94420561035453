import React,{useState} from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Lottie from 'lottie-react';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF","#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF","#ff0066","#9C1AFF", "#7700FF", "#4400FF","#FF008C"];

export const MenuItem = ({ i,name,linkto,animationData,isOpen,toggle}) => {
  const style = { border: `2px solid ${colors[i]}` };
  const [isHover,setisHover]=useState(false);


  return (
    <>
    {linkto==='https://hrm.thedatech.com/login'||linkto==='https://hrm.thedatech.com/employee/signup'?  <NavLink to={linkto}  onClick={() => isOpen && toggle()}>
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}
    >
      <div className="icon-placeholder" style={style}>
      <Lottie
      animationData={animationData}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 28, height: 28 }}
      id="icon-placeholdersvg"
    />
        </div>
      <div className="text-placeholder" style={style}>
        {name}
        </div>
    </motion.li>
    </NavLink>:  <NavLink to={linkto} onClick={() => isOpen && toggle()}>
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}
    >
      <div className="icon-placeholder" style={style}>
      <Lottie
      animationData={animationData}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 28, height: 28 }}
      id="icon-placeholdersvg"
    />
        </div>
      <div className="text-placeholder" style={style}>
        {name}
        </div>
    </motion.li>
    </NavLink>}
    </>
  );
};
