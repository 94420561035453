import React, { lazy, Suspense } from "react";
import "./App.css";
import "./Pages/Application/application.css";
import "./Pages/Services/service.css";
import "./Pages/Portfolio/portfolio.css";
import "./Pages/Contact/contact.css";
import "./Pages/FAQ/faq.css";
import { Circles } from "react-loader-spinner";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Component/Navbar/Navbar"; // Add your navbar if needed
import Loader from "./Component/Loader/Loader";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Lazy load your components
const Home = lazy(() => import("./Pages/Home/Home"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const Contact = lazy(() => import("./Pages/Contact/Contact")); // Fixed import
const Portfolio = lazy(() => import("./Pages/Portfolio/Portfolio"));
const Services = lazy(() => import("./Pages/Services/Services"));
const ServiceDetail = lazy(() => import("./Pages/Services/ServiceDetail"));
const Career = lazy(() => import("./Pages/Career/Career"));
const FAQ = lazy(() => import("./Pages/FAQ/FAQ"));
const Testimonials = lazy(() => import("./Pages/Testimonials/Testimonials"));
const Application = lazy(() => import("./Pages/Application/Application"));
const EVerification = lazy(() => import("./Pages/EVerification/EVerification"));
const BlogDetail = lazy(() => import("./Pages/Blogs/BlogDetail"));
const TermsCondition = lazy(() =>
  import("./Pages/TermsCondition/TermsCondition")
);
const SolutionDetail = lazy(() => import("./Pages/Application/SolutionDetail"));
const PortfolioForm = lazy(() => import("./Pages/PortfolioForm"));
const PortfolioDetail = lazy(() => import("./Pages/Portfolio/PortfolioDetail"));
const About = lazy(() => import("./Pages/About/About"));

function RouteLoader() {

  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        height: "100vh", // Full height of the viewport
      }}
    >
      <Circles
        height="120"
        width="120"
        color="#ff6600"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  ); // Define a simple loading component
}

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      
        <Navbar />
        <Suspense fallback={<Loader />}>
          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/portfolio/:category_name"
              element={<PortfolioDetail />}
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/:name" element={<ServiceDetail />} />
            <Route path="/privacy-policy" element={<Career />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/faq/:slug" element={<FAQ />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/solutions" element={<Application />} />
            <Route path="/eVerification" element={<EVerification />} />
            <Route path="/terms-&-condition" element={<TermsCondition />} />
            <Route path="/blogs/:title" element={<BlogDetail />} />
            <Route path="/solutions/:name" element={<SolutionDetail />} />
            <Route path="/form" element={<PortfolioForm />} />
          </Routes>
        </Suspense>
      
    </>
  );
}




export default App;
