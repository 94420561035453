import application from '../FILEJSON/other pages and includes/applications.json'
import portfolio from '../FILEJSON/portfolio/portfolio.json';
import service from '../FILEJSON/services/services.json';
import home from '../FILEJSON/home/home.json';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';

const data = [
    { id: 1, name: "Home", moverto: "/",animationData:home },
    { id: 2, name: "Services", moverto: "/Services",animationData:service },
    { id: 3, name: "Portfolio", moverto: "/Portfolio",animationData:portfolio },
    { id: 4, name: "Solutions", moverto: "/Solutions",animationData:application },
  ];

const BottomNav=()=>{
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const handleMouseEnter = (id) => {
        setHoveredIcon(id);
      };
    
      const handleMouseLeave = () => {
        setHoveredIcon(null);
      };

      const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
        },
      }));

    return(        
       <div className="bottomNav-container">
           <ul className="bottomNav-containernavul">
            {data &&
              data.map(({ id,animationData, name, moverto }) => (
                <BootstrapTooltip title={name}  key={id}>
                <li
                  className={`nav-item`}
                  onMouseEnter={() => handleMouseEnter(id)}
                  onMouseLeave={() => handleMouseLeave(id)}
                >
                  <NavLink
                    className="nav-link"
                    activeclassname="activelink"
                    aria-current="page"
                    to={moverto}
                  >
                 <Lottie
      animationData={animationData}
      loop={!hoveredIcon === id}
      isStopped={hoveredIcon !== id}
      autoplay={hoveredIcon === id}
      style={{ width:35, height: 30 }}
    />
                  </NavLink>
                </li>
                </BootstrapTooltip>
              ))}
          </ul>
       </div>
    )
}

export default BottomNav;