import React,{useState,useEffect} from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import about from '../FILEJSON/other pages and includes/about.json';
import blogs from '../FILEJSON/other pages and includes/blogs.json';
import careers from '../FILEJSON/other pages and includes/Careers.json';
import clientlogin from '../FILEJSON/other pages and includes/Login.json';
import contact from '../FILEJSON/other pages and includes/Contactpage.json';
import emplogin from '../FILEJSON/other pages and includes/Getstarted.json';
import empverification from '../FILEJSON/other pages and includes/empverification.json';
import faq from '../FILEJSON/other pages and includes/faq.json';
import privacy from '../FILEJSON/other pages and includes/privacy-policy.json';
import testimonial from '../FILEJSON/other pages and includes/testimonials.json';
import portfolio from '../FILEJSON/portfolio/portfolio.json';
import service from '../FILEJSON/services/services.json';
import tc from "../FILEJSON/other pages and includes/t&c's.json";
import application from '../FILEJSON/other pages and includes/applications.json'
import loginIcon from '../FILEJSON/navbar/loginiconnavbar.json'


const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2, background: { duration: 0.2 } }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1, background: { duration: 0.3 } }
  }
};



export const Navigation = ({isOpen,toggle}) => {
  const [height, setHeight] = useState(0);



    useEffect(() => {
        function handleResize() {
            const screenHeight = window.innerHeight;
            const screenWidth = window.innerWidth;
            const newHeight = screenWidth <= 475 ? screenHeight - 120 : screenHeight - 88; // 85.3% of screen height  style={{ height: `${height}px` }}
            setHeight(newHeight);
        }

        // Initial adjustment
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  return(
    <motion.ul variants={variants} className={isOpen?'bgblack':'bgblacknone'} style={{ height: `${height}px` }}>
    {itemIds.map(({id,name,icon,linkto,animationData}) => (
      <MenuItem i={id} key={id} name={name} icon={icon} linkto={linkto} animationData={animationData} isOpen={isOpen} toggle={toggle}/>
    ))}
  </motion.ul>
  )
}


const itemIds = [{id:1,name:'Login',linkto:'https://hrm.thedatech.com/login',animationData:loginIcon},{id:2,name:'Register',linkto:'https://hrm.thedatech.com/client-signup',animationData:emplogin},{id:3,name:'Services',linkto:'/services',animationData:service},{id:4,name:'Solutions',linkto:'/solutions',animationData:application},{id:5,name:'Portfolios',linkto:'/portfolio',animationData:portfolio},{id:6,name:'About',linkto:'/about',animationData:about},{id:7,name:'Testimonials',linkto:'/testimonials',animationData:testimonial},{id:8,name:'Contact',linkto:'/contact',animationData:contact},{id:9,name:'Blogs',linkto:'/blogs',animationData:blogs},{id:10,name:"FAQ's",linkto:'/faq',animationData:faq},{id:11,name:'Privacy Policy',linkto:'/privacy-policy',animationData:privacy},{id:12,name:"Terms & C's",linkto:'/terms-&-condition',animationData:tc},{id:13,name:'Employee Verification',linkto:'/eVerification',animationData:empverification},{id:14,name:'Careers',linkto:'https://hrm.thedatech.com/employee/signup',animationData:careers}];