import React, { useEffect, useRef } from 'react';
import './VideoContainer.css';
import ReactDOM from 'react-dom';
const VideoContainer = ({ onVideoEnd }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleVideoEnd = () => {
      onVideoEnd(); // Callback to trigger navigation when video ends
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, [onVideoEnd]);

  return ReactDOM.createPortal(
    <div className="fullscreen-video-container">
      <video
        ref={videoRef}
        className="fullscreen-video"
        src="/page-loader-video/loader.mp4"
        autoPlay
        muted
      />
    </div>,
    document.body
  );
};




export default VideoContainer;
